import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1242.000000 1186.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g viewBox="0 0 980.000000 980.000000" transform="translate(0.000000,1186.000000) scale(0.100000,-0.100000)">

        <path d="M5985 9370 c-394 -37 -761 -161 -1100 -373 -155 -98 -282 -199 -428
-344 -283 -282 -466 -580 -611 -993 -31 -90 -75 -277 -91 -390 -35 -237 -30
-582 11 -813 53 -307 197 -665 371 -925 116 -172 179 -250 317 -387 329 -327
726 -551 1171 -658 201 -49 360 -67 586 -67 223 0 378 18 584 67 397 95 754
282 1075 565 417 367 712 916 797 1484 25 168 25 563 0 724 -98 628 -417 1183
-907 1576 -340 272 -737 447 -1170 515 -143 22 -464 32 -605 19z m500 -411
c112 -13 321 -66 451 -114 633 -234 1118 -776 1288 -1438 49 -191 60 -293 60
-532 0 -232 -12 -326 -65 -518 -225 -814 -913 -1415 -1742 -1522 -137 -17
-375 -20 -515 -5 -131 14 -323 61 -459 111 l-113 42 0 854 0 853 23 0 c12 0
279 1 592 3 l570 2 3 198 2 197 -795 0 -795 0 0 -935 c0 -514 -3 -935 -6 -935
-12 0 -128 95 -210 173 -270 255 -462 574 -569 947 -55 191 -69 306 -70 555 0
260 18 395 81 600 51 165 162 408 193 425 13 7 324 10 928 10 971 0 1015 -2
1178 -51 338 -102 618 -403 697 -751 31 -136 31 -350 0 -472 -48 -192 -144
-357 -286 -494 -98 -94 -181 -152 -290 -202 -118 -55 -240 -84 -381 -93 l-40
-2 -3 -197 -2 -198 60 0 c70 0 239 24 324 46 219 57 477 212 641 386 283 300
420 659 402 1053 -20 433 -216 812 -557 1076 -165 128 -341 211 -554 262
l-131 31 -844 6 -844 5 69 67 c179 174 423 329 679 432 93 38 302 96 415 116
160 29 409 32 615 9z"/>
<path d="M6540 4108 c0 -7 -1 -125 -1 -263 l0 -250 65 -3 66 -3 0 266 0 265
-65 0 c-45 0 -65 -4 -65 -12z"/>
<path d="M3843 4090 c-129 -26 -212 -125 -213 -256 0 -154 136 -268 302 -250
72 7 114 24 162 66 102 90 112 251 22 356 -58 67 -174 103 -273 84z m123 -126
c65 -31 92 -124 56 -193 -25 -50 -63 -74 -116 -74 -78 0 -136 60 -136 141 0
110 98 172 196 126z"/>
<path d="M5590 4036 l0 -55 73 -3 72 -3 3 -192 2 -193 70 0 70 0 2 193 3 192
73 3 73 3 -3 52 -3 52 -217 3 -218 2 0 -54z"/>
<path d="M4411 3962 c-26 -13 -35 -14 -38 -4 -3 8 -25 12 -64 12 l-59 0 0
-260 0 -260 65 0 65 0 0 84 0 84 31 -15 c42 -21 126 -20 167 1 74 38 116 146
92 234 -30 112 -159 173 -259 124z m109 -120 c29 -36 27 -96 -5 -127 -52 -53
-135 -15 -135 62 0 89 89 131 140 65z"/>
<path d="M4847 3966 c-108 -40 -158 -166 -107 -272 51 -104 219 -143 332 -76
35 21 35 31 -2 67 -27 26 -31 27 -59 15 -61 -25 -151 -4 -151 35 0 13 23 15
135 15 l135 0 0 45 c0 60 -31 119 -80 151 -49 32 -145 42 -203 20z m142 -114
l24 -27 -78 -3 c-42 -2 -80 0 -83 2 -7 8 29 47 53 56 28 12 56 2 84 -28z"/>
<path d="M5306 3965 c-47 -17 -94 -59 -112 -102 -30 -70 -10 -178 43 -225 51
-46 167 -65 245 -39 35 11 88 61 88 82 0 5 -22 20 -49 33 l-48 25 -23 -24
c-38 -41 -100 -33 -125 16 -50 96 47 183 130 117 l28 -22 43 24 c24 12 44 28
44 35 0 18 -51 62 -92 79 -46 19 -120 20 -172 1z"/>
<path d="M6180 3964 c-42 -18 -86 -59 -107 -98 -15 -29 -19 -109 -7 -149 12
-42 57 -89 103 -110 79 -35 214 -23 255 22 16 18 15 21 -13 51 -28 31 -31 32
-60 20 -17 -7 -50 -12 -73 -12 -34 1 -48 7 -67 29 l-24 28 141 3 142 3 0 37
c0 45 -29 109 -61 139 -56 51 -158 68 -229 37z m143 -102 c36 -31 21 -42 -58
-42 -41 0 -75 2 -75 4 0 19 18 40 44 52 34 14 61 10 89 -14z"/>
<path d="M6866 3966 c-80 -30 -126 -97 -126 -186 0 -100 62 -170 170 -190 69
-13 150 2 188 35 l27 24 -31 31 -31 32 -46 -16 c-62 -21 -137 1 -137 40 0 11
27 14 135 14 l135 0 0 42 c0 59 -33 121 -82 153 -51 34 -142 43 -202 21z m143
-114 l24 -27 -78 -3 c-42 -2 -79 -1 -81 1 -10 9 10 39 35 52 34 19 70 11 100
-23z"/>
<path d="M7336 3970 c-142 -43 -190 -229 -84 -327 82 -77 256 -74 318 5 11 13
20 29 20 33 0 5 -21 20 -48 33 l-47 24 -28 -24 c-60 -51 -137 -14 -137 66 0
30 6 47 25 65 31 32 86 34 115 5 11 -11 23 -20 27 -20 14 0 93 42 93 50 0 19
-47 64 -84 81 -43 20 -120 23 -170 9z"/>
<path d="M7732 3955 c-125 -64 -148 -215 -47 -311 68 -66 175 -79 265 -34 72
37 100 87 100 179 0 109 -74 180 -199 188 -57 4 -73 1 -119 -22z m167 -111
c25 -31 27 -74 6 -113 -40 -78 -145 -41 -145 50 0 57 29 89 81 89 29 0 42 -6
58 -26z"/>
<path d="M8288 3962 c-30 -16 -38 -17 -38 -6 0 11 -16 14 -65 14 l-65 0 0
-190 0 -190 68 0 67 0 -3 89 c-4 111 10 168 44 181 35 14 41 13 64 -10 18 -18
20 -33 20 -140 l0 -120 70 0 70 0 0 119 c0 93 3 123 15 135 25 25 54 29 80 12
24 -16 25 -20 25 -141 l0 -125 71 0 71 0 -4 138 c-3 113 -7 144 -24 176 -39
76 -130 97 -219 52 l-48 -25 -30 21 c-43 31 -121 35 -169 10z"/>
<path d="M6198 3362 l-698 -2 0 -315 0 -315 710 0 710 0 0 314 c0 243 -3 315
-12 317 -7 2 -327 3 -710 1z m-240 -232 c47 -21 51 -29 22 -55 -17 -15 -24
-16 -46 -7 -59 28 -114 -1 -114 -59 0 -66 49 -98 110 -73 29 13 35 12 52 -2
25 -23 23 -29 -16 -53 -61 -38 -150 -23 -194 34 -54 68 -28 180 50 215 54 25
82 25 136 0z m299 -6 c44 -28 65 -74 60 -132 -8 -105 -114 -163 -214 -116
-107 52 -100 209 12 260 36 17 108 11 142 -12z m321 3 c11 -12 19 -11 50 4 46
24 79 24 119 -1 42 -25 55 -72 51 -180 l-3 -85 -35 0 -35 0 -3 94 c-1 52 -8
101 -14 108 -6 7 -23 13 -38 13 -15 0 -32 -6 -38 -13 -6 -7 -13 -56 -14 -108
l-3 -94 -35 0 -35 0 -3 94 c-1 52 -8 101 -14 108 -13 16 -60 17 -76 1 -8 -8
-12 -47 -12 -110 l0 -98 -35 0 -35 0 0 141 0 142 43 -5 c23 -3 51 -1 62 3 27
11 85 3 103 -14z m-883 -201 c19 -29 10 -54 -21 -62 -30 -8 -54 11 -54 42 0
43 51 57 75 20z"/>
<path d="M6122 3057 c-37 -39 -28 -98 19 -122 54 -28 118 23 104 85 -13 58
-83 80 -123 37z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
